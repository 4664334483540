import { styled } from 'utils/emotion';
import { colors } from 'components/Layout/variables';
import media from 'utils/mediaqueries';

export const ShareStyled = styled('div')(({ theme }) => ({
    textAlign: 'right',

    [media(0, 'tablet')]: {
     textAlign: 'left'
    },
}));

export const EmailShareButtonStyled = styled('div')(({ theme }) => ({
    'rect': {
        fill: colors.green
    }
}));


export const ShareButton = styled('span')(({ theme }) => ({
   display: 'inline-block',
   verticalAlign: 'middle',
   margin: '40px 0px 0px 40px',
   fontSize: 0,
   lineHeight: 0,

   [media(0, 'tablet')]: {
    marginLeft: 0,
    marginRight: 30,
   },
}));


export const Text = styled('span')(({ theme }) => ({
   display: 'inline-block',
   verticalAlign: 'middle',
   margin: '40px 40px 0px 40px',
   fontWeight: 600,
   textTransform: 'uppercase',
   fontSize: 12,

   [media(0, 'tablet')]: {
    display: 'block',
    marginLeft: 0,
    marginRight: 0
   },
}));

