import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import { colors, sizes } from "components/Layout/variables";
import Headline from "components/Headline";
import GridItemComponent from "components/GridItem";

export const BgStyled = styled("div")({
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
});

export const ArticleWrapperStyled = styled("div")({});

export const GridItem = styled(GridItemComponent)({
  [media(0, "tabletFluid")]: {
    padding: 0,
  },
});

export const ArticleStyled = styled("article")({}, (props) => ({
  color: props.theme.colorPrimary,
  backgroundColor: props.theme.backgroundPrimary,
}));

export const HeaderStyled = styled("header")(
  {
    position: "relative",

    "&::after": {
      display: "block",
      content: '""',
      top: 0,
      left: 0,
      height: 10,
      width: 200,
    },
  },
  (props) => ({
    "&::after": {
      backgroundColor: props.colorTertiary,
    },
  })
);

export const HeadlineStyled = styled(Headline)(
  {
    textTransform: "none",
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "2em",
    wordBreak: "break-word",

    [media("tabletFluid")]: {
      fontSize: "2.8em",
    },
    [media(0, 410)]: {
      // wordBreak: 'break-all',
    },
  },
  (props) => ({
    marginTop: props.theme.space,
    marginBottom: props.theme.space,

    [media("tabletFluid")]: {
      marginBottom: props.theme.spaceDouble,
    },
  })
);

export const MetaStyled = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  color: colors.greyLightest,
  fontWeight: 700,
  padding: `${sizes.spaceThirds}px 0`,
});

export const MagazineArticleCTAWrapStyled = styled("div")({});

export const CategoryStyled = styled("span")(
  {
    textTransform: "uppercase",
  },
  (props) => ({
    color: props.theme.colorTertiary,
  })
);

export const DateStyled = styled("span")({});

export const ContentStyled = styled("main")({}, (props) => ({
  padding: `0 ${props.theme.space}px ${props.theme.space}px`,

  [media(0, "tabletFluid")]: {
    "p, ul li, ol li": { fontSize: 17, lineHeight: "28px" },
    // padding: `0 16px ${props.theme.spaceDouble}px`,
  },
  [media("tabletFluid")]: {
    padding: `0 ${props.theme.spaceDouble}px ${props.theme.spaceDouble}px`,
  },
  [media("laptop")]: {
    padding: `0 ${props.theme.spaceFive}px ${props.theme.spaceTriple}px`,
  },

  "ul, ol": {
    paddingLeft: 20,
  },

  "ol li, ul li": {
    marginBottom: 10,
  },

  "ul li": {
    textIndent: -23,
    marginBottom: 10,
  },
}));
