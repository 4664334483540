import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { parseAsText } from 'utils/parser'

import Grid from 'components/Grid'
import Redactor from 'components/Redactor'
import Image from 'components/Image'
import SwitchTheme from 'components/SwitchTheme'
import Overlay from 'components/Overlay'
import Button from 'components/Button'
import Video from 'components/Video'
import MagazineArticleCTA from './MagazineArticleCTA'
import Share from '../Share/Share'
import Newsletter from 'components/Newsletter'

import {
  BgStyled,
  ArticleWrapperStyled,
  ArticleStyled,
  HeaderStyled,
  HeadlineStyled,
  ContentStyled,
  MetaStyled,
  CategoryStyled,
  DateStyled,
  GridItem,
} from './styles'

const renderArticleComponent = (item, pageContext) => {
  const magazineArticleComponent = item.__typename.replace(
    'Craft_MagazineArticleContentMagazineArticle',
    ''
  )

  switch (magazineArticleComponent) {
    case 'Text':
      return (
        <Redactor key={`${item?.id}`} content={item?.articleText?.content} />
      )
    case 'Image':
      return (
        <Image
          key={`${item?.id}`}
          fluid={item?.articleImage?.[0]}
          aspectRatio={16 / 9}
          imageSignature={item.artikelImageSignature}
          style={{ margin: '24px 0' }}
        />
      )
    case 'Video':
      return (
        <Video
          muted={false}
          controls
          key={`${item?.id}`}
          url={item.articleVideo}
        />
      )
    case 'CTA':
      return (
        <MagazineArticleCTA
          headline={item.headline}
          headlineTag={item.headlineTag}
          link={item.ctaLink}
        />
      )
    case 'Newsletter':
      return (
        <Newsletter
          headline={item.sectionNewsletterHeadline}
          headlineTag={item.sectionNewsletterHeadlineTag}
          text={item.sectionNewsletterText}
          padding={item.sectionNewsletterPadding}
          theme="alternative"
          image={item.sectionNewsletterImage}
          bulletImage={item.sectionNewsletterBulletImage}
          segmentId={item.sectionNewsletterSegmentId}
          buttonText={item.sectionNewsletterButtonText}
          specialLawText={item.sectionNewsletterLawText}
          pageContext={pageContext}
          className={null}
        />
      )
    default:
      return null
  }
}

const MagazineArticle = ({ pageContext }) => {
  const staticData = useStaticQuery(graphql`
    query MagazineArticleGlobalsStatic {
      craft {
        # Magazine
        dataMagazineDe: entries(section: magazine, siteId: 1) {
          __typename
          ... on Craft_Magazine {
            magazineHeroBackground {
              ...CraftImageFluid
            }
          }
        }

        # Magazine
        dataMagazineEn: entries(section: magazine, siteId: 2) {
          __typename
          ... on Craft_Magazine {
            magazineHeroBackground {
              ...CraftImageFluid
            }
          }
        }
      }
    }
  `)

  const { dataMagazineDe, dataMagazineEn } = staticData?.craft

  const dataMagazine =
    pageContext.siteId === 1 ? dataMagazineDe : dataMagazineEn

  const dataMagazineArticle = pageContext?.entry

  const bgImage = dataMagazine?.[0]?.magazineHeroBackground

  const {
    title,
    articleHeroImage,
    postDate,
    language,
    magazineCat,
    magazineArticleContent,
  } = dataMagazineArticle

  const postDateFormatted = new Intl.DateTimeFormat(language, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(postDate * 1000))

  const basePath = pageContext?.baseUrl
  const fullUri = pageContext?.entry?.fullUri
  const url = basePath + fullUri

  return (
    <>
      <Overlay position="fixed" background="overlayMagazineBackground" />
      {bgImage?.[0] && (
        <BgStyled>
          <Image
            fluid={bgImage?.[0]}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: 0,
              left: 0,
            }}
            args={{ maxWidth: 1920 }}
          />
          <Overlay />
        </BgStyled>
      )}
      <Grid minHeight="100vh" padding="120px 0">
        <GridItem>
          <ArticleWrapperStyled>
            <div style={{ marginLeft: 10 }}>
              <Button
                stripped
                themeName="default"
                icon="arrowLeft"
                iconLeft
                back>
                Back
              </Button>
            </div>
            <SwitchTheme name="alternative">
              <ArticleStyled>
                <HeaderStyled>
                  {articleHeroImage?.[0] && (
                    <Image fluid={articleHeroImage?.[0]} aspectRatio={16 / 9} />
                  )}
                </HeaderStyled>
                <ContentStyled>
                  <MetaStyled>
                    <CategoryStyled>
                      {magazineCat &&
                        magazineCat.map((category) => category.title)}
                    </CategoryStyled>
                    {postDate && <DateStyled>{postDateFormatted}</DateStyled>}
                  </MetaStyled>
                  <HeadlineStyled element="h1" margin="0 0 24px 0" uppercase>
                    {parseAsText(title)}
                  </HeadlineStyled>
                  {magazineArticleContent.map((component) =>
                    renderArticleComponent(component, pageContext)
                  )}
                </ContentStyled>
              </ArticleStyled>
            </SwitchTheme>

            <div style={{ marginLeft: 10 }}>
              <Share url={url} />
            </div>
            {/* TODO: Paginating next and prev article */}
            {/* TODO: Back to magazin button */}
          </ArticleWrapperStyled>
        </GridItem>
      </Grid>
    </>
  )
}

export default MagazineArticle

/**
 * This query fragment is outsourced to gatsby/createPages.js
 */
// export const query = graphql``;
