import React, { Component } from 'react'
import ReactPlayer from 'react-player/lazy'
import { styled } from 'utils/emotion'

const ReactPlayerWrapperStyled = styled('div')({
  position: 'relative',
  paddingBottom: '56.25%' /* 16:9 ratio */,
  height: 0,
  marginBottom: '24px',
  /*
    YouTube video behaves strange.
    This is a ratio 'hack' to set the height of videos to he full width of container.
    see: https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
  * */
  'iframe, video': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})

class Video extends Component {
  render() {
    const { url, muted, controls } = this.props
    const isYoutube = url.includes('youtube')
    return (
      <ReactPlayerWrapperStyled isYoutube={isYoutube}>
        <ReactPlayer
          width="100%"
          height="100%"
          url={url}
          muted={muted}
          controls={controls}
        />
      </ReactPlayerWrapperStyled>
    )
  }
}

export default Video
