import React from "react";
import PropTypes from "prop-types";
import Headline from "components/Headline";
import Button from "components/Button";
import { MagazineArticleCTAWrapStyled } from "./styles";

const MagazineArticleCTA = ({ headline, link, headlineTag }) => {
  if (!link) return null;

  return (
    <MagazineArticleCTAWrapStyled>
      {headline && (
        <Headline margin="50px 0px 30px" element={headlineTag}>
          {headline}
        </Headline>
      )}
      <Button
        to={link.url}
        target={link.target}
        icon="arrowRight"
        themeName="green"
        uppercase
        filled
        stretch
      >
        {link.text}
      </Button>
    </MagazineArticleCTAWrapStyled>
  );
};

MagazineArticleCTA.propTypes = {
  headline: PropTypes.string,
  link: PropTypes.object,
  headlineTag: PropTypes.string,
};

MagazineArticleCTA.defaultProps = {
  headline: null,
  link: null,
  headlineTag: null,
};

export default MagazineArticleCTA;
