import React from "react"

import MagazineArticle from "components/MagazineArticle"
import Seo from "components/Seo"
import { Banner } from "components/Banner"
import Popup from "components/Popup"

export default function TemplateMagazineArticle({ pageContext }) {
  return (
    <>
      <Seo pageContext={pageContext} />
      <MagazineArticle pageContext={pageContext} />
      <Banner curPageTitle='article' pageContext={pageContext} />
      <Popup pageContext={pageContext} />
    </>
  )
}
