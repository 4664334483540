import React from "react";
import Redactor from "components/Redactor";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  // WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  // InstapaperIcon,
  // LineIcon,
  LinkedinIcon,
  // LivejournalIcon,
  // MailruIcon,
  // OKIcon,
  // PinterestIcon,
  // PocketIcon,
  // RedditIcon,
  // TelegramIcon,
  // TumblrIcon,
  TwitterIcon,
  // ViberIcon,
  // VKIcon,
  // WeiboIcon,
  WhatsappIcon
  // WorkplaceIcon,
} from "react-share";

import {
  ShareStyled,
  EmailShareButtonStyled,
  ShareButton,
  Text
} from "./Share.styles";

export default function Share({ url }) {
  return (
    <ShareStyled>
      <Text>Teilen</Text>

      <ShareButton>
        <EmailShareButton url={url}>
          <EmailShareButtonStyled>
            <EmailIcon />
          </EmailShareButtonStyled>
        </EmailShareButton>
      </ShareButton>

      <ShareButton>
        <FacebookShareButton url={url}>
          <FacebookIcon />
        </FacebookShareButton>
      </ShareButton>

      <ShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon />
        </TwitterShareButton>
      </ShareButton>

      <ShareButton>
        <WhatsappShareButton url={url}>
          <WhatsappIcon />
        </WhatsappShareButton>
      </ShareButton>

      <ShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon />
        </LinkedinShareButton>
      </ShareButton>
    </ShareStyled>
  );
}
